import axios from "axios";

import { authHeader } from "../helpers/authHelper";

const BASE_URL = (window.location.hostname === "maheer.dmorent.com" || window.location.hostname === "seller.dmorent.com") ? "https://api.viziontools.com/api/v1/" : "https://api.viziontools.com/api/v1/";
// const BASE_URL = (window.location.hostname === "maheer.dmorent.com" || window.location.hostname === "seller.dmorent.com") ? "https://api.dmorent.com/api/v1/" : "https://2770-2405-201-200d-1c68-f031-3697-52ae-ab4f.ngrok-free.app/laundry/api/v1/";

export const API_PREFIX = "";
const axiosApi = axios.create({
  baseURL: `${BASE_URL}/${API_PREFIX}`,
});

export const axiosInstance = axiosApi;

export async function get(url, config = {}) {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(url, { params: config, headers: authHeader() })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function patch(url, data, config = {}) {
  return await axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response)
    .catch((error) => error.response);
}
export async function post(url, data, config = {}) {
  return new Promise((resolve, reject) => {
    axiosApi
      .post(url, { ...data }, { ...config, headers: authHeader() })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function put(url, data, config = {}) {
  return new Promise((resolve, reject) => {
    axiosApi
      .put(url, { ...data }, { ...config, headers: authHeader() })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export async function del(url, config = {}) {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(url, { ...config, headers: authHeader() })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function isSuccessResp(status) {
  if (status >= 200 && status <= 299) {
    return true;
  }
  return false;
}

export const thunkHandler = async (asyncFn, thunkAPI) => {
  try {
    const response = await asyncFn;
    return response.data;
  } catch (err) {
    // if (
    //   err?.response &&
    //   err?.response?.data &&
    //   err?.response?.data?.payload &&
    //   err?.response?.data?.payload?.context === "jwt expired"
    // ) {
    //   window.location = "/";
    //   deleteSession();
    // }
    return thunkAPI.rejectWithValue(err.response.data);
  }
};
